import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import DefaultBlock from "/scripts/default/block";

// Register Plugins
gsap.registerPlugin(ScrollTrigger);

export default class Demos extends DefaultBlock {
	init() {
		const DOM = {
			select: this.block.querySelector(".demos__plugins-groups-select"),
			pluginSelect: this.block.querySelector(".demos__plugin-select"),
			plugins: this.block.querySelectorAll(".demos__plugins-groups-plugins"),
			buttons: this.block.querySelectorAll("[data-demos-btn]"),
			demoButton: this.block.querySelector("[data-js=demo-button]"),
			docsButton: this.block.querySelector("[data-js=docs-button]"),
			iframe: this.block.querySelector(".js-demo-iframe"),
		};
		this.DOM = DOM;

		if (!this.DOM.select || !this.DOM.plugins) return; // These only exist on the pricing page.

		this.demos = [
			{
				label: "Draggable",
				demo: "https://gsap.com/demos/draggable-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/yLGqXzZ/4b6f3a7c39c03c70f5714fcaeb621b74",
			},
			{
				label: "Flip",
				demo: "https://gsap.com/demos/flip-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/abPjLXM/8d966ec68b6049e6e2ef0e8a103350f1",
			},
			{
				label: "MotionPath",
				docs: "/docs/v3/Plugins/MotionPathPlugin/",
				demo: "https://gsap.com/demos/motionpath-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/XWoBypQ/6e2b1f4aa6f8d3eaf58b5dee14dcc047",
			},
			{
				label: "Observer",
				demo: "https://gsap.com/demos/observer-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/MWZBzVx/10b5c6ff57817ae73521e89507b6b098",
			},
			{
				label: "Pixi",
				docs: "/docs/v3/Plugins/PixiPlugin/",
				demo: "https://gsap.com/demos/pixi-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/RwEBEGM/60c48f7b8759c3dfc1d968b7933662ac",
			},
			{
				label: "ScrollTo",
				docs: "/docs/v3/Plugins/ScrollToPlugin/",
				demo: "https://gsap.com/demos/scrollto-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/abPjMPv/c8b66b7c95249052b3ed9083b312c223",
			},
			{
				label: "ScrollTrigger",
				demo: "https://gsap.com/demos/scrolltrigger-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/abPampm/a2d12f3d8397078d3f031aaaba1b5166",
			},
			{
				label: "Text",
				docs: "/docs/v3/Plugins/TextPlugin/",
				demo: "https://gsap.com/demos/text-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/gOZdRqG/5a078fd21ea63c46923f739f2acd35be",
			},
			{
				label: "Physics2D",
				docs: "/docs/v3/Plugins/Physics2DPlugin/",
				demo: "https://gsap.com/demos/physics2d-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/YzdOVbK/9d71c2a8fba370750b141cc79e3b6e78",
			},
			{
				label: "DrawSVG",
				docs: "/docs/v3/Plugins/DrawSVGPlugin/",
				demo: "https://gsap.com/demos/drawsvg-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/VwqEYdr/92153ee6bedcb123888a24e670ce80d0 ",
			},
			{
				label: "PhysicsProps",
				docs: "/docs/v3/Plugins/PhysicsPropsPlugin/",
				demo: "https://gsap.com/demos/physicsprops-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/ZEVqYmb/cac05879b8b3e6c0ffdcac81f02c9371",
			},
			{
				label: "ScrambleText",
				docs: "/docs/v3/Plugins/ScrambleTextPlugin/",
				demo: "https://gsap.com/demos/scrambletext-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/QWzZwxR/3cd896781ae5d2a4525bf403f610718c",
			},
			{
				label: "CustomBounce",
				docs: "/docs/v3/Easing/CustomBounce/",
				demo: "https://gsap.com/demos/custombounce-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/poqxvOz/7abe6c897f47831196296e71f5fa6ea1",
			},
			{
				label: "CustomWiggle",
				docs: "/docs/v3/Easing/CustomWiggle/",
				demo: "https://gsap.com/demos/customwiggle-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/OJrBPoB/c29b6c31b7bf45b8ab0f7c6bb12fe26d",
			},

			{
				label: "MorphSVG",
				docs: "/docs/v3/Plugins/MorphSVGPlugin/",
				demo: "https://gsap.com/demos/morphsvg-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/BavOJVM/5912e6c31ea671cd3bc347b5adf0a599",
			},
			{
				label: "Inertia",
				docs: "/docs/v3/Plugins/InertiaPlugin/",
				demo: "https://gsap.com/demos/inertia-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/NWeLyrq/d669938b4b29c7fcec771f9e6a378382",
			},
			{
				label: "SplitText",
				demo: "https://gsap.com/demos/splittext-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/xxmaNYj/231e5c51fb2885bdeea366bfe5c63c22",
			},
			{
				label: "MotionPathHelper",
				demo: "https://gsap.com/demos/motionpathhelper-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/WNLgVbg/b1f427b4b5ab608a0e5eac3856aed105",
			},
			{
				label: "GSDevTools",
				demo: "https://gsap.com/demos/gsdevtools-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/bGOmbNX/6a7faddc3ec1abab17d9213aee03832a ",
			},
			{
				label: "ScrollSmoother",
				demo: "https://gsap.com/demos/scrollsmoother-demo.html",
				codepen:
					"https://codepen.io/GreenSock/pen/GRPYKQb/ba77b64f5f959d5847a0abf52f1f702b",
			},
		];

		this.handleSelect();
		this.handlePluginSelect();
	}

	initEvents() {
		const activeClass = "is-active";

		this.DOM.buttons.forEach((button) => {
			button.addEventListener("click", (e) => {
				this.updateDemo(e.target.innerText);
				this.DOM.buttons.forEach((button) => {
					button.classList.remove(activeClass);
				});

				button.classList.add(activeClass);
			});
		});
	}

	handleSelect() {
		// This is for the pricing page.
		// When the select is changed, the active class is removed from all plugins and added to the selected plugin.
		this.DOM.select.addEventListener("change", (e) => {
			this.DOM.plugins.forEach((plugin) => {
				plugin.classList.remove("demos__plugins-groups-plugins--active");
			});

			this.DOM.plugins[this.DOM.select.value].classList.add(
				"demos__plugins-groups-plugins--active"
			);
		});
	}

	handlePluginSelect() {
		// This is for the pricing page.
		// When the select is changed, the active class is removed from all plugins and added to the selected plugin.
		this.DOM.pluginSelect.addEventListener("change", (e) => {
			this.updateDemo(this.DOM.pluginSelect.value);
		});
	}

	findDemosByLabel(labelToFind) {
		const lowerCaseLabelToFind = labelToFind.toLowerCase();

		for (const item of this.demos) {
			if (item.label.toLowerCase() === lowerCaseLabelToFind) {
				return {
					demo: item.demo,
					codepen: item.codepen,
					docs: item.docs,
				};
			}
		}

		return {
			demo: null,
			codepen: null,
		};
	}

	updateDemo(value) {
		const { demo, codepen, docs } = this.findDemosByLabel(value);

		this.DOM.iframe.src = demo;

		this.DOM.demoButton.setAttribute("href", codepen);

		let docsUrl = docs && docs.length ? docs : `/docs/v3/Plugins/${value}`;
		this.DOM.docsButton.setAttribute("href", docsUrl);
	}
}
